import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'
import { SEO } from "../components/seo"

const contact = () => {
    return (
        <div>
            <Layout>
                <div className="container page-width center inner-pages page-short">
                    <h2>Contact</h2>
                    <h3 class="subtitle">Get In Touch!</h3>

                    <div className="emphasize mt30">
                        <p><strong>Sjever-sjeverozapad</strong><small><br /> obrt za računalno programiranje, vl. Igor Bujas, Zagreb, Croatia</small></p>
                        <p><a href="mailto:info@north-northwest.com">info@north-northwest.com</a></p>
                    </div>

                    <p className="mt30">If you want to connect, don't hesitate to shoot me a message. I usually reply within 24-48 hours.</p>
                    <p>Cheers!</p>

                    <hr />

                </div>        
            </Layout> 
        </div>
    )
}

export default contact

export const Head = () => (
    <SEO />
  )